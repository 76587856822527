import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from '../components/ToastMsg';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)',
    }),
};

const BillUpdate = (props) => {
    const [billData, setBillData] = useState({
        billId: '',
        customer: '',
        billNo: '',
        billMonth: '',
        billDate: '',
        billYear: '',
        total: '',
        netPayable: '',
        dueAmount: '',
        items: [{
            item: '',
            unit: '',
            quantity: '',
            stock: '',
            price: '',
            amount: '',
        }],
        sendSms: 'no',
        billCycle: '', // added state for Bill Cycle
        billFromDate: '', // added state for Bill From Date
        billToDate: '', // added state for Bill To Date
    });

    useEffect(() => {
        const { id } = props.match.params;
        setBillData((prevState) => ({ ...prevState, billId: id }));
        getBillDetails(id);
    }, [props.match.params]);

    useEffect(()=>{
        const billingCycle = billData.customer.billingCycle;
        calculateBillDates(billingCycle);

    },[billData.billDate,billData.customer])
    const getBillDetails = (billId) => {
        fetch(`${process.env.REACT_APP_API_URL}bill_details/${billId}`, { method: 'GET' })
            .then((resp) => resp.json())
            .then((resp) => {
                setBillData((prevState) => ({
                    ...prevState,
                    billId: resp.id,
                    billNo: resp.billNo,
                    billMonth: resp.billMonth,
                    billYear: resp.billYear,
                    billDate: resp.billFromDate,
                    billFromDate : resp.billFromDate,
                    billToDate : resp.billToDate,
                    billCycle: resp.customer.billingCycle,
                    total: resp.total,
                    customer: {...resp.customer, label: `${resp.customer.name} ${resp.customer.mobileNo}`, value: resp.customer.id},
                    dueAmount: resp.dueAmount,
                    netPayable: resp.netPayable,
                    items: resp.bill_item,
                }));
                calcTotal(resp.bill_item);
            })
            .catch((error) => console.log(error));
    };

    const getCustomer = (inputValue, callback) => {
        const url = inputValue
            ? `${process.env.REACT_APP_API_URL}customer_search?q=${inputValue}`
            : `${process.env.REACT_APP_API_URL}customer_search`;
        const jwt = JSON.parse(localStorage.getItem('MyToken'));
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` },
        };
        setTimeout(() => {
            fetch(url, requestOptions)
                .then((resp) => resp.json())
                .then((resp) => {
                    const options = resp.data.map((element) => ({
                        ...element,
                        label: `${element.name} ${element.mobileNo}`,
                        value: element.id,
                    }));
                    callback(options);
                })
                .catch((error) => console.log(error));
        });
    };

    const handleCustomerChange = (customer) => {
        
        setBillData((prevState) => ({ ...prevState, customer }));
    };

    const handleInputOnChange = (evt) => {
        const { name, value } = evt.target;
        setBillData((prevState) => ({ ...prevState, [name]: value }));
     
    };

    // Handle checkbox change for sending SMS with null safety
    const handleCheckboxChange = (e) => {
        const isChecked = e?.target?.checked; // Safe check for e.target
        setBillData((prevData) => ({
        ...prevData,
        sendSms: isChecked ? "yes" : "no", // Update sendSms state
        }));
    };

    const onQuantityChange = (index) => (evt) => {
        const newItems = billData.items.map((row, rindex) => {
            if (index === rindex) {
                row.quantity = Number(evt.target.value);
                row.amount = ((Number(row.quantity) * Number(row.price)) - Number(row.discount)).toFixed(2);
            }
            return row;
        });
        setBillData((prevState) => ({ ...prevState, items: newItems }));
        calcTotal(newItems);
    };

    const onDiscountChange = (index) => (evt) => {
        const newItems = billData.items.map((row, rindex) => {
            if (index === rindex) {
                row.discount = Number(evt.target.value);
                row.amount = ((Number(row.quantity) * Number(row.price)) - Number(row.discount)).toFixed(2);
            }
            return row;
        });
        setBillData((prevState) => ({ ...prevState, items: newItems }));
        calcTotal(newItems);
    };

    const onItemChange = (index) => (item) => {
      
        let price = 0;
        if (item.value == '1') price = Number(billData.customer.monthlyFee);
        if (item.value == '2') price = Number(billData.customer.monthlyFee);
   
        const newItems = billData.items.map((row, rindex) => {
            if (index !== rindex) return row;
            return {
                ...row,
                item,
                unit: item.unitName,
                quantity: 1,
                price: price,
                discount: 0,
                amount: Number(price),
            };
        });
        setBillData((prevState) => ({ ...prevState, items: newItems }));
        calcTotal(newItems);
    };

    const onPriceChange = (index) => (evt) => {
        const newItems = billData.items.map((row, rindex) => {
            if (index === rindex) {
                row.price = evt.target.value;
                row.amount = (Number(row.quantity) * Number(row.price)).toFixed(2);
            }
            return row;
        });
        setBillData((prevState) => ({ ...prevState, items: newItems }));
        calcTotal(newItems);
    };

    const calcTotal = (items) => {
        const totalDiscount = items.reduce((acc, item) => acc + Number(item.discount), 0).toFixed(2);
        const totalAmount = items.reduce((acc, item) => acc + Number(item.amount), 0).toFixed(2);
        setBillData((prevState) => ({
            ...prevState,
            totalDiscount,
            total: totalAmount,
        }));
    };

    const addItem = () => {
        setBillData((prevState) => ({
            ...prevState,
            items: [...prevState.items, { warehouse: '', item: '', unit: '', quantity: '', stock: '', price: '', amount: '' }],
        }));
    };

    const removeItem = (index) => {
        const newItems = billData.items.filter((_, rindex) => index !== rindex);
        setBillData((prevState) => ({ ...prevState, items: newItems }));
        calcTotal(newItems);
    };



    const calculateBillDates = (billCycle) => {
        const billDate = new Date(billData.billDate);
        if (isNaN(billDate.getTime())) {
            console.error('Invalid billDate:', billData.billDate);
            return; // If billDate is invalid, skip the calculation
        }
    
        let billFromDate = new Date(billDate);
        let billToDate = new Date(billDate);
    
        switch (billCycle) {
            case '2':
                billToDate.setMonth(billFromDate.getMonth() + 1);
                break;
            case '3':
                billToDate.setMonth(billFromDate.getMonth() + 2);
                break;
            case '4':
                billToDate.setMonth(billFromDate.getMonth() + 3);
                break;
            case '6':
                billToDate.setMonth(billFromDate.getMonth() + 5);
                break;
            case '12':
                billToDate.setMonth(billFromDate.getMonth() + 11);
                break;
            default:
                break;
        }
    
        setBillData((prevState) => ({
            ...prevState,
            billFromDate: formatMonthYear(billFromDate),
            billToDate: formatMonthYear(billToDate),
        }));
    }
    



    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return ''; // Return an empty string if the date is invalid
        }
        return date.toISOString().split('T')[0]; // Format the date to YYYY-MM-DD
    };
    
    const formatMonthYear = (date) => {
        const options = { year: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const fetchItemData = (inputValue, callback) => {
        const url = inputValue
            ? `${process.env.REACT_APP_API_URL}item/item_search?q=${inputValue}`
            : `${process.env.REACT_APP_API_URL}item/item_search`;
        setTimeout(() => {
            fetch(url, { method: 'GET' })
                .then((resp) => resp.json())
                .then((resp) => {
                    const options = resp.data.map((element) => ({
                        id: element.itemId,
                        label: element.itemName,
                        value: element.itemId,
                    }));
                    callback(options);
                })
                .catch((error) => console.log(error));
        });
    };

    const handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
        const { areaOption, blockOption, ...formData } = billData;
        //console.log(formData);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "bill_update", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                console.log(resp);

                if (resp.success == true) {
                    let successMsg = [`Bill ID# ${resp.data.id}`];

                    toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBillData({
                        billId: '',
                        customer: '',
                        billNo: '',
                        billMonth: '',
                        billDate: '',
                        billYear: '',
                        total: '',
                        netPayable: '',
                        dueAmount: '',
                        items: [{
                            item: '',
                            unit: '',
                            quantity: '',
                            stock: '',
                            price: '',
                            amount: '',
                        }],
                        sendSms: 'yes',
                    });
                    props.history.push('/bill-list')
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
        }

    });


    };


        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Bill  Edit</h3>
                </div>
                <div className="col-md-12">
                    <div className="featured-box featured-box-primary text-left mt-2">
                        <div className="box-content">

                            <form onSubmit={handleSubmit} >

                                <div className="form-group row">
                                    <label for="customer" className="col-lg-2 col-form-label">Customer</label>
                                    <div className="col-lg-10">
                                        <AsyncSelect
                                            id="customer"
                                            value={billData.customer}
                                            defaultOptions
                                            loadOptions={getCustomer}
                                            //loadOptions={promiseOptions}
                                            placeholder="Select Customer"
                                            onChange={handleCustomerChange}
                                        // onChange={(e) => {
                                        //this.onSearchChange(e);
                                        //  }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="customer" className="col-lg-2 col-form-label">Bill Date</label>
                                    <div className="col-lg-10">
                                        <input type="date" className="form-control" name="billDate" value={billData.billDate} onChange={handleInputOnChange} />
                                    </div>

                                </div>

                                {billData.customer.billingCycle > 1 &&
                            <div className="form-group row">
                                
                                <div className="col-lg-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="billFromDate"
                                        value={billData.billFromDate}
                                        readOnly
                                    />
                                </div>
                                <div className="col-lg-1">To </div>
                                <div className="col-lg-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="billToDate"
                                        value={billData.billToDate}
                                        readOnly
                                    />
                                </div>
                            </div>}

                                    {/* <pre>
                                        {JSON.stringify(billData, null, 2)}
                                    </pre> */}
                                

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="kt-section">
                                            <div className="kt-separator kt-separator--dashed"></div>
                                            <div className="table-responsive">
                                            <table className="table table-bordered table-condensed">

                                                <tbody>

                                                    <tr>
                                                        <th style={{ 'width': '5%' }}>Sl.</th>
                                                        <th style={{ width: '15%', minWidth: '200px' }}>Item</th>
                                                        <th style={{ 'width': '10%' }}>Quantity</th>
                                                        <th style={{ 'width': '15%',minWidth: '120px' }}>Price</th>
                                                        {/* <th style={{ 'width': '10%' }}>Discount</th> */}
                                                        <th style={{ 'width': '10%' }}>Amount</th>
                                                        <th style={{ 'width': '10%' }}>Action</th>
                                                    </tr>
                                                    {billData.items.map((item, index) =>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td><AsyncSelect
                                                                key={index}
                                                                value={item.item}
                                                                defaultOptions
                                                                loadOptions={fetchItemData}
                                                                //loadOptions={promiseOptions}
                                                                placeholder="Select Item"
                                                                onChange={onItemChange(index)}
                                                                styles={customStylesSelect}
                                                            // onChange={(e) => {
                                                            //this.onSearchChange(e);
                                                            //  }}
                                                            />
                                                            </td>
                                                            <td><input className="form-control" type="number" value={item.quantity} onChange={onQuantityChange(index)} /></td>
                                                            <td><input className="form-control" type="number" value={item.price} onChange={onPriceChange(index)} /></td>
                                                            {/* <td><input className="form-control" type="number" value={item.discount} onChange={onDiscountChange(index)} /></td> */}
                                                            <td>{item.amount}</td>
                                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={() => removeItem(index)}>Remove</button></td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="form-group row">
                                                                <div className="col-lg-2">
                                                                    <button type="button" className="btn btn-primary btn-sm" onClick={addItem}>Add Item</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    {/* <tr>
                                                        <th colSpan="5" className="text-right">Discount </th>
                                                        <th>{totalDiscount}</th>
                                                    </tr> */}
                                                    <tr>
                                                        <th colSpan="5" className="text-right">Subtotal </th>
                                                        <th>{billData.total}</th>
                                                    </tr>
                                                </tfoot>

                                            </table>

                                            </div>

                                        </div>

                                        
                                    </div>

                                </div>
                                                            
                              
                                 <br/>
                                 {/* <div className="form-group row">
                                    <label for="customer" className="col-lg-2 col-form-label">Send SMS</label>
                                    <div className="col-lg-10">
                                        <select className="form-control" name="sendSms" value={sendSms} onChange={handleInputOnChange}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div> */}
                                 <div className="form-group row">
                                <label htmlFor="sendSms" className="col-lg-2">Send SMS</label>
                                <div className="col-lg-10">
                                <label className="checkbox">
                                    <input 
                                    type="checkbox" 
                                    name="sendSms" 
                                    id="sendSms" 
                                    checked={billData.sendSms === "yes"} 
                                    onChange={handleCheckboxChange} 
                                    />
                                    <span></span>
                                </label>
                                </div>
                            </div>

                                <div className="form-group row">

                                    <div className="form-group col-lg-5">
                                        <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>

);
};

export default BillUpdate;
