import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ToastMsg from '../components/ToastMsg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const CustomerReg = (props) => {
    const [state, setState] = useState({
        name: "",
        mobileNo: "",
        alternateMobileNo: "",
        code: "",
        email: "",
        website: "",
        connectionDate: "",
        connectionCharge: "",
        monthlyFee: "",
        billDate: 1,
        package: "",
        category: "regular",
        status: "active",
        area: "",
        zone: "",
        line: "",
        road: "",
        address: "",
        gpsLocation: "",
        nid: "",
        birthCertificateNo: "",
        passportNo: "",
        drivingLicenceNo: "",
        isPppoeOrStatic: "pppoe",
        pppoe: "",
        pppoeUserName: "",
        pppoePassword: "",
        localAddress: "",
        remoteAddress: "",
        callerId: "",
        ipAddress: "",
        subnetMask: "",
        defaultGateway: "",
        primaryDns: "",
        alternateDns: "",
        onu: "",
        onuMacAddress: "",
        router: "",
        routerLogin: "",
        remoteManagement: "",
        wifi: "",
        note: "",
        areaOption: [],
        zoneOption: [],
        packageOption: [],
        billingCycleOptions: [1] // Default options: 1 month, 3 months, 12 months
    });

    useEffect(() => {
        getAllArea();
        getAllZone();
        getAllPackage();
    }, []);

    const getAllArea = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "area", {
                method: "GET",
            });
            const data = await response.json();
            setState((prevState) => ({ ...prevState, areaOption: data.data }));
        } catch (error) {
            console.error(error);
        }
    };

    const getAllZone = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "zone", {
                method: "GET",
            });
            const data = await response.json();
            setState((prevState) => ({ ...prevState, zoneOption: data.data }));
        } catch (error) {
            console.error(error);
        }
    };

    const getAllPackage = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "package", {
                method: "GET",
            });
            const data = await response.json();
            setState((prevState) => ({ ...prevState, packageOption: data.data }));
        } catch (error) {
            console.error(error);
        }
    };

    const getAreaBlock = async (areaId) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "area_block/" + areaId, {
                method: "GET",
            });
            const data = await response.json();
            setState((prevState) => ({ ...prevState, blockOption: data.data }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleAreaChange = (evt) => {
        const { value } = evt.target;
        if (value) {
            getAreaBlock(value); // Call getAreaBlock to fetch data based on the selected area.
        } else {
            setState((prevState) => ({ ...prevState, blockOption: [] }));
        }
        setState((prevState) => ({ ...prevState, area: value }));
    };

    const handleInputOnChange = evt => {
        const { name, value } = evt.target;
    
        setState(prev => {
          const newState = { ...prev, [name]: value };
    
          // Adjust billing cycle options if category changes to "special"
          if (name === 'category' && value === 'special') {
            newState.billingCycleOptions = [2,3, 4, 6,12];
            newState.billingCycle = "";
          } else if (name === 'category' && value !== 'special') {
            newState.billingCycleOptions = [1]; // Reset to default
            newState.billingCycle = "";
          }
    
          return newState;
        });
    
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const { billingCycleOptions,areaOption, zoneOption, packageOption, ...formData } = state;
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "customer_reg", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const resp = await response.json();

            if (resp.success) {
                toast.success(
                    <ToastMsg toastMessage={[`Customer ID# ${resp.data.id}`]} heading={resp.successMessage} />,
                    { position: toast.POSITION.TOP_RIGHT }
                );
                setState((prevState) => ({ ...prevState, ...formData }));
                props.history.push('/customer-list');
            } else {
                let errorsMessage = [];
                if (typeof resp.errorMessage === 'object') {
                    const errorsObj = resp.errorMessage;
                    Object.keys(errorsObj).forEach((value) => {
                        errorsObj[value].forEach((v) => {
                            errorsMessage.push(v);
                        });
                    });
                } else {
                    errorsMessage.push(resp.errorMessage || 'Something went wrong');
                }
                toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const {
        name,
        mobileNo,
        areaOption,
        zoneOption,
        // ...other fields
    } = state;

    const categoryOption = [{ name: 'Regular', value: 'regular' }, { name: 'Complimentary', value: 'complimentary' }, { name: 'Special', value: 'special' }];
    const statusOption = [{ name: 'Active', value: 'active' }, { name: 'Deactive', value: 'deactive' }, { name: 'Close', value: 'close' }];
    
    const getBillingCycleLabel = () => {
        const cycle = state.billingCycle || 1;
        return `${cycle > 1 ? cycle +' Months' : "Monthly"} Fee`;
      };

    return (
  
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">
                        Customer Registration
                </h3>
                </div>


                <div className="card-body">

                    <form onSubmit={handleSubmit} >
                    <div className="row">
                    <div className="col-md-5">
                        <div className="form-group row">
                            <TextField name="code" value={state.code} onChange={handleInputOnChange} label="ID" variant="outlined" size="small" fullWidth />
                        </div>
                        <div className="form-group row">
                            <TextField name="name" value={state.name} onChange={handleInputOnChange} label="Name" style={{ marginBottom: 10 }} variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">

                            <TextField name="mobileNo" value={state.mobileNo} onChange={handleInputOnChange} label="Mobile Number" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField name="alternateMobileNo" value={state.alternateMobileNo} onChange={handleInputOnChange} label="Alternate Mobile Number" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">
                            <TextField name="email" value={state.email} onChange={handleInputOnChange} label="Email" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">
                            <TextField name="website" value={state.website} onChange={handleInputOnChange} label="Website" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">
                            <TextField name="nid" value={state.nid} onChange={handleInputOnChange} label="National Id/Passport Number" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">

                            <div className="col-6">
                                <FormControl variant="outlined" size="small" fullWidth >
                                    <InputLabel htmlFor="">Area</InputLabel>
                                    <Select label="Area" name="area" onChange={handleAreaChange}>
                                        <MenuItem value="">Select Area</MenuItem >
                                        {state.areaOption.map(function (item, id) {
                                            return <MenuItem key={id} value={item.areaId}>{item.areaName}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>

                            </div>
                            <div className="col-6">
                                <FormControl variant="outlined" size="small" fullWidth  >
                                    <InputLabel htmlFor="" >Zone</InputLabel>
                                    <Select label="Zone" name="zone" onChange={handleInputOnChange} >
                                        <MenuItem value="">Select Block</MenuItem>
                                        {state.zoneOption.map((item, id) =>
                                            <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>

                        </div>


                        <div className="form-group row">

                            <TextField multiline rows={2} name="address" value={state.address} onChange={handleInputOnChange} label="Address" variant="outlined" size="small" fullWidth />

                        </div>
                        

                        <div className="form-group row">

                            <TextField name="connectionCharge" value={state.connectionCharge} onChange={handleInputOnChange} label="Connection Charge" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField type="date" name="connectionDate" value={state.connectionDate} onChange={handleInputOnChange} InputLabelProps={{
                                shrink: true,
                            }} label="Connection Date" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">
                            <FormControl variant="outlined" size="small" fullWidth >
                                <InputLabel htmlFor="">Package</InputLabel>
                                <Select label="Package" name="package" onChange={handleInputOnChange}>
                                    <MenuItem value="">Select Package</MenuItem >
                                    {state.packageOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </div>



                        <div className="form-group row">

                            <TextField type="number" name="billDate" value={state.billDate} onChange={handleInputOnChange} label="Bill Date" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField name="gpsLocation" value={state.gpsLocation} onChange={handleInputOnChange} label="GPS Location" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <FormControl variant="outlined" size="small" fullWidth  >
                                <InputLabel htmlFor="" >Category</InputLabel>
                                <Select label="Category" value={state.category} name="category" onChange={handleInputOnChange} >
                                    <MenuItem value="">Select Category</MenuItem>
                                    {categoryOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.value}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>

                        </div>

                        {state.category === 'special' &&
                        <div className='form-group row'>
                        <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Billing Cycle</InputLabel>
                        <Select
                        name="billingCycle"
                        value={state.billingCycle}
                        onChange={handleInputOnChange}
                        label="Billing Cycle"
                        >
                        <MenuItem value=''>Billing Cycle</MenuItem>
                        {state.billingCycleOptions.map(option => (
                            <MenuItem key={option} value={option}>
                            {`${option} month${option > 1 ? 's' : ''}`}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </div>
                    }
                    <div className='form-group row'>
                        <TextField
                            name='monthlyFee'
                            value={state.monthlyFee}
                            onChange={handleInputOnChange}
                            label={getBillingCycleLabel()}
                            variant='outlined'
                            size='small'
                            fullWidth
                        />
                        </div>

                        <div className="form-group row">

                            <FormControl variant="outlined" size="small" fullWidth  >
                                <InputLabel htmlFor="" >Status</InputLabel>
                                <Select label="Status" name="status" onChange={handleInputOnChange} >
                                    <MenuItem value="">Select Status</MenuItem>
                                    {statusOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.value}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>

                        </div>




                        </div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-5">
                            
                       
                        <div className="form-group row">  
                        <FormControl>
                       
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="isPppoeOrStatic" value={state.isPppoeOrStatic} onChange={handleInputOnChange}
                        >
                            <FormControlLabel value="pppoe" control={<Radio />} label="PPPoE" />
                            <FormControlLabel value="static" control={<Radio />} label="Static" />
                            
                        </RadioGroup>
                        </FormControl>
                        </div>

                        
                        {state.isPppoeOrStatic === "pppoe" && <>
                        <div className="form-group row">

                            <TextField type="text" name="pppoe" value={state.pppoe} onChange={handleInputOnChange} label="PPPoE" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                            <div className="col-lg-6">
                                <TextField name="pppoeUserName" value={state.pppoeUserName} onChange={handleInputOnChange} label="User Name" variant="outlined" size="small" fullWidth />
                            </div>

                            <div className="col-lg-6">
                                <TextField name="pppoePassword" value={state.pppoePassword} onChange={handleInputOnChange} label="Password" variant="outlined" size="small" fullWidth />
                            </div>

                        </div>
                        <div className="form-group row">

                            <TextField type="text" name="localAddress" value={state.localAddress} onChange={handleInputOnChange} label="Local Address" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField type="text" name="remoteAddress" value={state.remoteAddress} onChange={handleInputOnChange} label="Remote Address" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                            <TextField type="text" name="callerId" value={state.callerId} onChange={handleInputOnChange} label="Caller ID" variant="outlined" size="small" fullWidth />

                        </div>
                        </>
                       }
                        {state.isPppoeOrStatic === "static" && <>
                        <div className="form-group row">

                        <TextField type="text" name="ipAddress" value={state.ipAddress} onChange={handleInputOnChange} label="IP Address" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                        <TextField type="text" name="subnetMask" value={state.subnetMask} onChange={handleInputOnChange} label="Subnet Mask" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                        <TextField type="text" name="defaultGateway" value={state.defaultGateway} onChange={handleInputOnChange} label="Default Gateway" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                        <TextField type="text" name="primaryDns" value={state.primaryDns} onChange={handleInputOnChange} label="Primary DNS" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                        <TextField type="text" name="alternateDns" value={state.alternateDns} onChange={handleInputOnChange} label="Alternate DNS" variant="outlined" size="small" fullWidth />

                        </div>

                        </>
                       }

                    <div className="form-group row">

                    <TextField type="text" name="onu" value={state.onu} onChange={handleInputOnChange} label="ONU Name" variant="outlined" size="small" fullWidth />

                    </div>

                    <div className="form-group row">

                    <TextField type="text" name="onuMacAddress" value={state.onuMacAddress} onChange={handleInputOnChange} label="ONU Mac Address" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="note" value={state.note} onChange={handleInputOnChange} label="Note" variant="outlined" size="small" fullWidth />

                    </div>

                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="router" value={state.router} onChange={handleInputOnChange} label="Router" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="routerLogin" value={state.routerLogin} onChange={handleInputOnChange} label="Router Login" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="remoteManagement" value={state.remoteManagement} onChange={handleInputOnChange} label="Router Remote Management" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="wifi" value={state.wifi} onChange={handleInputOnChange} label="WiFi" variant="outlined" size="small" fullWidth />

                    </div>




                        </div>
                        <div className="form-group row">

                        <div className="form-group col-lg-5">
                            <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                        </div>
                        </div>
                        </div>
                    </form>
                    

                </div>
                {/* <pre>
                    {JSON.stringify(state, null, 2)}
                </pre> */}

                <ToastContainer />
            </div>


);
};

export default CustomerReg;
