import React, { useState } from 'react'
import CustomerDetail from '../components/CustomerDetails'
import '../styles/customerSearch.css'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { toAbsoluteUrl } from '../../_metronic/_helpers'
import SVG from 'react-inlinesvg'

const CustomerSearch = () => {
  const [query, setQuery] = useState('')
  const [customers, setCustomers] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fetchCustomers = async (query) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}customer_search?q=${query}`,
        getRequestOptions()
      )
      const data = await response.json()
      setCustomers(data.data)
      setError('')
      setIsSubmitting(true)
      setLoading(false);
    } catch (err) {
      setError('Customer not found')
      setCustomers([])
      setLoading(false);
    }
  }

  const handleSearchChange = (event) => {
    setCustomers([]);
    setQuery(event.target.value)

    if (event.target.value.length > 2) {

      setLoading(true);
     
      fetchCustomers(event.target.value);
      
    }
  };
  



  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>Customer Search</h3>
      </div>

      <div className='card-body'>
        <div style={{ marginLeft: '-12.5px', marginRight: '-12.5px' }}>
          <div className='customer-search-container'>
           
              <input
                className='form-control  form-control-solid'
                type='text'
                placeholder='Search by ID or name'
                value={query}
                onChange={handleSearchChange}
                style={{ marginBottom: '10px' }}
              />
                <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
            </div>

            {error && <div className='error'>{error}</div>}
            {customers.length > 0 && <CustomerDetail customers={customers} />}

            {isSubmitting && customers.length === 0  && (
              <div className='no-results'>
                <p>No customers found.</p>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerSearch
