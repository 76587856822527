import React, { useState, useEffect } from 'react';
import { Tab, Tabs,Form,Nav, Col, Row, Button, InputGroup, FormControl, Modal, Toast } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';
import { Link,useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../_metronic/_helpers';   
import CustomerBillList  from '../components/CustomerBillList'; 
import { get, set } from 'lodash';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';


const CustomerDetails = () => {
    const [customerDetails, setCustomerDetails] = useState({
        id:"",name: "", mobileNo: "", code: "", email: "", connectionDate: "", area: "", block: "",
        line: "", road: "", address: "", nid: "", pppoe: "", pppoeUserName: "", pppoePassword: "",
        localAddress: "", remoteAddress: "", ipAddress: "", subnetMask: "", defaultGateway: "",
        primaryDns: "", alternateDns: "", onu: "", onuMacAddress: "", balance: 0,sendSms: "",billDate:"",
        pppoeSerect:"",
    });
     

    const [paymentData, setPaymentData] = useState([]);
    const [billData, setBillData] = useState([]);
    const { id: customerId } = useParams();

    const [customerSmsData, setCustomerSmsData] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const [selectedBill, setSelectedBill] = useState(null);
    const [showSmsModal, setShowSmsModal] = useState(false);
    const handleSmsModalClose = () => setShowSmsModal(false);
    const [billSmsText, setBillSmsText] = useState("");

    useEffect(() => {
        getCustomerDetails(customerId);
        getCustomerPaymentDetails(customerId);
        getCustomerBillDetails(customerId);
        getCustomerSms(customerId);
    }, [customerId]);

    const handleSmsModalOpen = (billId) => {
        setShowSmsModal(true);
        getBillSmsMessage(billId);
        setSelectedBill(billId);
    }

    const getBillSmsMessage = (billId) => {
        
        fetch(`${process.env.REACT_APP_API_URL}get_bill_sms/${billId}`, getRequestOptions())
            .then((resp) => resp.json())
            .then((resp) => {
                setBillSmsText(resp.data);
            })
            .catch((error) => console.error("Error fetching bill details:", error));

    }


    const getCustomerSms = (customerId) => {

        fetch(
			process.env.REACT_APP_API_URL + 'get_customer_sms/' + customerId,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
                setCustomerSmsData(resp);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
        
    }

    
    const onPaymentCollection = (data) => {
       // console.log(data);
        handleModalClose();
        getCustomerBillDetails(customerId);
        getCustomerPaymentDetails(customerId);
        Swal.fire('Success!', 'Payment collected successfully.', 'success');        
    }



    const getCustomerDetails = (customerId) => {
        fetch(`${process.env.REACT_APP_API_URL}customer_details/${customerId}`, { method: "GET" })
            .then((resp) => resp.json())
            .then((resp) => {
                setCustomerDetails({
                    id : resp.id,
                    name: resp.name,
                    customerCode: resp.code,
                    area: resp.area ?? { name: '' },
                    block: resp.block ?? { name: '' },
                    line: resp.line,
                    road: resp.road ?? { name: '' },
                    mobileNo: resp.mobileNo,
                    address: resp.address,
                    email: resp.email,
                    connectionDate: resp.connectionDate,
                    monthlyFee: resp.monthlyFee,
                    pppoe: resp.pppoe,
                    pppoeUserName: resp.pppoeUserName,
                    pppoePassword: resp.pppoePassword,
                    localAddress: resp.localAddress,
                    remoteAddress: resp.remoteAddress,
                    ipAddress: resp.ipAddress,
                    subnetMask: resp.subnetMask,
                    defaultGateway: resp.defaultGateway,
                    primaryDns: resp.primaryDns,
                    alternateDns: resp.alternateDns,
                    onu: resp.onu,
                    onuMacAddress: resp.onuMacAddress,
                    sendSms: resp.sendSms,
                    billDate: resp.billDate,
                    pppoeSecret: resp.pppoeSecret,
                });
                console.log(resp.pppoeSecret);
            })
           
            .catch((error) => console.error("Error fetching customer details:", error));
    };

    const getCustomerPaymentDetails = (customerId) => {
        fetch(`${process.env.REACT_APP_API_URL}customer_payment_list/${customerId}`, { method: "GET" })
            .then((resp) => resp.json())
            .then((resp) => setPaymentData(resp))
            .catch((error) => console.error("Error fetching payment details:", error));
    };

    const getCustomerBillDetails = (customerId) => {
        fetch(`${process.env.REACT_APP_API_URL}get_customer_bill/${customerId}`, { method: "GET" })
            .then((resp) => resp.json())
            .then((resp) => {
                setBillData(resp.billData);
                setCustomerDetails((prevState) => ({ ...prevState, balance: resp.netPayable }));
            })
            .catch((error) => console.error("Error fetching bill details:", error));
    };

    const  handleBillSmsSend = (billId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to send bill SMS!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = {
                    billId,
                    billSmsText
                }
                const requestOptions = postRequestOptions(formData);
         
                fetch(`${process.env.REACT_APP_API_URL}send_bill_sms`, requestOptions)
                .then((response) => response.json())
                .then(() => 
                    Swal.fire('Success!', 'Bill SMS sent successfully.', 'success')
                    .then(() => setShowSmsModal(false))
                
                ).catch((error) => Swal.fire('Error!', error.message, 'error'));
            }
        });
        
    }


    return (
        <>
        <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title">Customer Details</h3>
                <div className="card-toolbar">
                    <Link to={`/customer-edit/${customerId}`}>
                    <button type="button" className="btn btn-primary font-weight-bolder">
                                <span className="svg-icon svg-icon-md">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                </span>

                            </button>
                    </Link>
                </div>
                
            </div>

            <div className="col-md-12">
                <div className="featured-box featured-box-primary text-left mt-2">
                    <div className="box-content">
                        <div className="customer-details col-md-12 col-sm-12">

                            <div className="customer-card ">
                                    <div className="customer-header">
                                        <span>ID {customerDetails.customerCode}</span>
                                        <span>{customerDetails.name}<br/>
                                            {customerDetails.mobileNo}
                                        </span>
                                        <span onClick={handleModalOpen}>Balance ৳{customerDetails.balance}</span>
                                    </div>
                                    <div className="customer-info">
                                        <p>
                                            {customerDetails.address}
                                        </p>
                                    </div>
                            </div>
                            
                        </div>
                        <Tabs defaultActiveKey="network-info" id="customer-tabs">
                            <Tab eventKey="personal-info" title="Personal Details">
                                <table className="table table-bordered table-hover">
                                    <tbody> 
                                        <tr><th>Connnection since </th><td>{customerDetails.connectionDate}</td></tr>
                                        <tr><th>Monthly Fee</th><td>{customerDetails.monthlyFee}</td></tr>
                                        <tr><th>Alternate Mobile No</th><td>{customerDetails.alternateMobileNo}</td></tr>
                                        <tr><th>Email</th><td>{customerDetails.email}</td></tr>
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="network-info" title="Network">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr><th>PPPoE</th><td>{customerDetails.pppoe}</td></tr>
                                        <tr><th>PPPoE User Name</th><td>{customerDetails.pppoeUserName}</td></tr>
                                        <tr><th>PPPoE Password</th><td>{customerDetails.pppoePassword}</td></tr>
                                        <tr><th>Profile</th><td>{customerDetails.pppoeSecret?.profile}</td></tr>
                                        <tr><th>Last Logged Out</th><td>{customerDetails.pppoeSecret?.["last-logged-out"] || "N/A"}</td></tr>
                                        <tr><th>Last Disconnect Reason</th><td>{customerDetails.pppoeSecret?.["last-disconnect-reason"] || "N/A"}</td></tr>
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="payment-info" title="Payment Info">
                            <Button variant="primary" onClick={handleModalOpen} style={{ float: 'right', marginTop: '10px'}}>
                                Payment
                            </Button>
                                <table className="table table-hover">
                                    <thead>
                                        <tr><th>Date</th><th> Amount</th><th>Account</th></tr>
                                    </thead>
                                    <tbody>
                                        {paymentData.map((payment, index) => (
                                            <tr key={index}>
                                                <td>{payment.paymentDate}</td>
                                                <td>{payment.amount}</td>
                                                <td>{payment.ledgerName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="bill-info" title="Bill Info">
                                <table className="table table-hover">
                                    <thead>
                                        <tr><th>Bill Date</th><th>Bill No</th><th>Bill Amount</th><th>Action</th></tr>
                                    </thead>
                                    <tbody>
                                        {billData.map((bill, index) => (
                                            <tr key={index}>
                                                <td>{bill.billDate}</td>
                                                <td>{bill.billNo}</td>
                                                <td>{bill.billAmount}</td>
                                                <td>
                                                    <div className="btn-group btn-group-sm">
                                                        <Link to={`/bill-details/${bill.billId}`}>
                                                            <button className="btn btn-primary mr-2">Bill Details</button>
                                                        </Link>
                                                        {index === 0 && (
                                                            <button className="btn btn-success" onClick={() => handleSmsModalOpen(bill.billId)}>
                                                                Send SMS
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Tab>

                            <Tab eventKey="customer-sms" title="Customer SMS">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SMS Date</th>
                                            <th>SMS Message</th>
                                            <th>Type</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerSmsData.map((sms, index) => (
                                            <tr key={index}>
                                                <td>{sms.smsDate}</td>
                                                <td>{sms.smsBody}</td>
                                                <td>{sms.type}</td>
                                                <td>{sms.smsStatus}</td>
                                            </tr>
                                        ))}

                                        </tbody>
                                </table>
                            </Tab>

                            <Tab eventKey="notification" title="Notification">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>SMS Notification </th>
                                            <td>{customerDetails.sendSms}</td>
                                        </tr>

                                        <tr>
                                            <th> Notification Start Date </th>
                                            <td>{ Number(customerDetails.billDate)+1} th day of the month</td>
                                        </tr>

                                        <tr>
                                            <th>Notification Interval</th>
                                            <td>5 Days</td>
                                        </tr>
                                        <tr>
                                            <th>End Date </th>
                                            <td>{(Number(customerDetails.billDate) + 1) + (5 * 3)}th day of the month</td>
                                        </tr>

                                    </tbody>
                                
                                       
                                </table>    

                            </Tab>
                            

                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={showModal} onHide={handleModalClose} size="lg">
                 <Modal.Header >
                    <Modal.Title>Payment Collection </Modal.Title>
                     <Button variant="secondary" onClick={handleModalClose}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    {/* Pass the customer object as props */}
                    <div style={{marginLeft:"-12.5px",marginRight:"-12.5px"}}>
                    <CustomerBillList customer={customerDetails} onPaymentCollection={onPaymentCollection} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* modal to send bill sms */}
            <Modal show={showSmsModal} onHide={handleSmsModalClose} >
                 <Modal.Header closeButton>
                    <Modal.Title> Send SMS</Modal.Title>
                   
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="smsMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control   as="textarea" rows={4} value={billSmsText} onChange={(e) => setBillSmsText(e.target.value)} />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSmsModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>handleBillSmsSend(selectedBill)}>
                            Send SMS
                        </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};

export default CustomerDetails;
