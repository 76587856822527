import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { toast, ToastContainer } from 'react-toastify';
import ToastMsg from './ToastMsg';
import Swal from 'sweetalert2';
import   './paymentStyle.css';

const CustomerBillList = (props) => {
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [customer, setCustomer] = useState({ name: "", id: "", code: "", address: "" });
    const [billData, setBillData] = useState([]);
    // set payment data  current date
    const [paymentDate, setPaymentDate] = useState( new Date().toISOString().slice(0, 10) );
    const [netPayable, setNetPayable] = useState("");
    const [dueAmount, setDueAmount] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [merchantMobileNo, setMerchantMobileNo] = useState("");
    const [account, setAccount] = useState("");
    const [accountType, setAccountType] = useState("");
    const [collectionBy, setCollectionBy] = useState("");
    const [fromMobileNo, setFromMobileNo] = useState("");
    const [remarks, setRemarks] = useState("");
    const [sendSms, setSendSms] = useState("yes");
    const [accOption, setAccOption] = useState([]);
    const [empOption, setEmpOption] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        getCustomerBill();
        getAcched();
        getEmployee();
        getUserInfo();
    }, []);

    useEffect(() => {
        if (props.customer.id !== customer.id || props.month !== props.month) {
            getCustomerBill();
        }
    }, [props.customer.id, props.month]);

    // colection by set  by user 
    useEffect(() => {
         setCollectionBy(userId);
    }, [userId]); 

    useEffect(() => {
        // Fetch the last acc_head for the customer
        const fetchLastAccHead = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}customer/last-acc-head/${props.customer.id}`);
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (data.success) {
              setAccount(data.data.last_acc_head);
            } else {
            
              console.error(data.message);
            }
          } catch (error) {
            
            console.error("Error fetching last acc_head:", error);
          }
        };
    
        if (props.customer.id) {
          fetchLastAccHead();
        }
      }, [props.customer.id]);


    const getUserInfo = () => {
        const jwt = JSON.parse(localStorage.getItem('MyToken'));
        fetch(process.env.REACT_APP_API_URL + "users/get_user_info", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt }
        })
        .then(resp => resp.json())
        .then(resp => {
            setUserId(resp.data.userId);
        })
        .catch(error => console.log(error));
    };

    const getCustomerBill = () => {
        const formData = { customer: props.customer };
        fetch(process.env.REACT_APP_API_URL + "customer_bill_list", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        })
        .then(resp => resp.json())
        .then(resp => {
            const billData = resp.billData.map(row => ({
                ...row,
                balance: row.billAmount - row.paidAmount,
                payAmount: 0,
                discount: 0,
                isSelect: 0
            }));
            setCustomer(resp.customer);
            setBillData(billData);
            setDueAmount(resp.dueAmount);
            setNetPayable(resp.netPayable);
        })
        .catch(error => console.log(error));
    };

    const onSelectBill = (index) => (evt) => {
        const newItems = billData.map((row, rindex) => {
            if (index === rindex) {
                row.isSelect = evt.target.checked ? 1 : 0;
                row.payAmount = row.isSelect ? row.balance : 0;
            }
            return row;
        });
        setBillData(newItems);
        calcTotal();
    };

    const onPayAmountChangeOld = (index) => (evt) => {
        const newItems = billData.map((row, rindex) => {
            if (index === rindex) row.payAmount = evt.target.value;
            return row;
        });
        setBillData(newItems);
        calcTotal();
    };


    const onPayAmountChange = (index) => (evt) => {
        const enteredPayAmount = Number(evt.target.value) || 0;
        const newItems = billData.map((row, rindex) => {
            if (index === rindex) {
                const discountedBalance = Math.max(0, row.balance - (row.discount || 0)); // Calculate the remaining balance after discount
                row.payAmount = Math.min(enteredPayAmount, discountedBalance); // Ensure payAmount doesn't exceed discounted balance
            }
            return row;
        });
        setBillData(newItems);
        calcTotal();
    };
    


    const onDiscountChange = (index) => (evt) => {
        const discountValue = Number(evt.target.value) || 0;
        const newItems = billData.map((row, rindex) => {
            if (index === rindex) {
                row.discount = discountValue;
                row.payAmount = Math.max(0, row.balance - discountValue); // Ensure no negative values
            }
            return row;
        });
        setBillData(newItems);
        calcTotal();
    };
    


    const calcTotal = () => {
        const total = billData.reduce((a, b) => Number(a) + Number(b.payAmount || 0), 0);
        setPaymentAmount(total.toFixed(2));
    };

    const handleAccountOnChange = (evt) => {
        const selectedAccount = accOption.find(account => account.ledgerId === evt.target.value);
        setAccount(evt.target.value);
        setAccountType(selectedAccount?.referenceType || "");
    };

    const handleInputOnChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'paymentAmount':
                setPaymentAmount(value);
                break;
            case 'paymentDate':
                setPaymentDate(value);
                break;
            case 'fromMobileNo':
                setFromMobileNo(value);
                break;
            case 'collectionBy':
                setCollectionBy(value);
                break;
            case 'remarks':
                setRemarks(value);
                break;
            default:
                break;
        }
    };

    const getAcched = () => {
        fetch(process.env.REACT_APP_API_URL + "cash_bank_gl_search", { method: "GET" })
            .then(resp => resp.json())
            .then(resp => setAccOption(resp.data))
            .catch(error => console.log(error));
    };

    const getEmployee = () => {
        fetch(process.env.REACT_APP_API_URL + "get_employee", { method: "GET" })
            .then(resp => resp.json())
            .then(resp => setEmpOption(resp.data))
            .catch(error => console.log(error));
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to collect payment!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = JSON.parse(localStorage.getItem('MyToken'));
                const formData = {
                    customer, billData, paymentDate, paymentAmount, netPayable, dueAmount, paymentMethod,
                    merchantMobileNo, account, accountType, collectionBy, fromMobileNo, remarks, sendSms
                };
                fetch(process.env.REACT_APP_API_URL + "payment_received", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.success) {
                        toast.success(<ToastMsg toastMessage={[`Payment ID# ${resp.data.id}`]} heading={resp.successMessage} />, { position: toast.POSITION.TOP_RIGHT });
                        setPaymentModalShow(false);
                        setBillData([]);
                        setPaymentAmount(0);
                        setPaymentMethod("");
                        setFromMobileNo("");
                        setRemarks("");
                        setAccount("");
                        setAccountType("");
                        setCollectionBy("");
                        setSendSms("no");
                        getCustomerBill();
                        if (props.onPaymentCollection != undefined){
                        props.onPaymentCollection();
                        }

                    } else {
                        var errorsMessage = [];
    
                        if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                            var errorsObj = resp.errorMessage;
                            Object.keys(errorsObj).forEach(function (value) {
                                errorsObj[value].forEach(function (v) {
                                    errorsMessage.push(v)
                                    //errorsMessage += '<div>' + v + '</div>';
                                });
    
                            });
    
                        } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                            //errorsMessage = resp.errorMessage;
                            errorsMessage.push(resp.errorMessage);
                        } else {
                            //errorsMessage = "Something went wrong";
                            errorsMessage.push("Something went wrong");
                        }
                        //console.log(errorsMessage);
                        toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        
                    }
                })
                .catch(error => console.log(error));
            }
        });
    };

 
        return (

            <div className="col-md-12">
            <div className="row">
             
                <div className="payment-collection-header col-md-12 col-sm-12">
                    <div>ID {customer.code}</div>
                    <div>
                        {customer.name}<br />
                        {customer.mobileNo}

                    </div>
                    <div>Balance ৳{netPayable}</div>
                </div>
                
                <div className="col-md-12 col-sm-12 ">
                    <div  style={{'width': '100%', overflow: 'auto',marginTop: '10px', marginBottom: '10px'}}>
                    <table className="table table-bordered"> 
                        <tbody>
                            <tr>
                                <th className='sticky'>Bill No</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                    <td> <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={bill.isSelect === 1}
                                        onChange={onSelectBill(index)}
                                    />
                                    <span>&nbsp;</span>
                                     {bill.billNo}
                                </label>
                                </td>
                                ))}
                            </tr>
                            <tr>
                                <th className='sticky'>Bill Date</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                    <td key={index}>{bill.billDate}</td>
                                ))}
                            </tr>
                            <tr>
                                <th className='sticky'>Bill Amount</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                    <td key={index}>{bill.billAmount}</td>
                            ))}
                            </tr>
                            <tr>
                                <th className='sticky'>Adjusted Paid Amount</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                    <td key={index}>{bill.paidAmount}</td>
                            ))}
                            </tr>
                            <tr>
                                <th className='sticky'>Balance</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                    <td key={index}>{bill.balance}</td>
                            ))}
                            </tr>
                            <tr>
                                <th className='sticky'>Discount</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                   <td key={index}>
                                   <input
                                       className="form-control"
                                       type="text"
                                       value={bill.discount}
                                       onChange={onDiscountChange(index)}
                                   />
                               </td>
                            ))}

                            </tr>
                            <tr>
                                <th className='sticky'>Received Amount</th>
                                { billData.length > 0 && billData.map((bill, index) => (
                                   <td key={index}>
                                   <input
                                       className="form-control"
                                       type="text"
                                       value={bill.payAmount}
                                       onChange={onPayAmountChange(index)}
                                   />
                               </td>
                            ))}

                            </tr>
                        </tbody>
                    </table>
                    </div>
                
                </div>
            </div>

            <form onSubmit={handleSubmit}>

         
            <div className="form-group">
              <div style={{'display': 'flex', 'flexDirection': 'row', 'justify-content': 'center'}}>

                {/* Account Selection */}
                <div style={{width: '50%', marginRight: '10px'}}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Account</InputLabel>
                        <Select name="account" value={account} onChange={handleAccountOnChange}>
                            <MenuItem value="">Select Account</MenuItem>
                            {accOption.map((item, id) => (
                                <MenuItem key={id} value={item.ledgerId}>{item.ledgerName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                {/* Payment Date */}
                <div style={{width: '50%'}}>
                    <TextField
                        name="paymentDate"
                        type="date"
                        value={paymentDate}
                        onChange={handleInputOnChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Payment Date"
                        InputLabelProps={{ shrink: true }}
                    />
                </div>

              </div>
               

            </div>
                
       

                <div className="form-group">
                <div style={{'display': 'flex', 'flexDirection': 'row', 'justify-content': 'center'}}>

                <div style={{width: '50%', marginRight: '10px'}}>
                <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Collection By</InputLabel>
                        <Select name="collectionBy" value={collectionBy} onChange={handleInputOnChange}>
                            <MenuItem value="">Select Employee</MenuItem>
                            {empOption.map((item, id) => (
                                <MenuItem key={id} value={item.employeeId}>{item.employeeName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div style={{width: '50%'}}>
                    <TextField
                        name="remarks"
                        value={remarks}
                        onChange={handleInputOnChange}
                        label="Remarks"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={2}
                        fullWidth
                    />
                    </div>
                    </div>
                </div>

                <div className="form-row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div >
                    <FormControl component="fieldset" variant="outlined" size="small" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sendSms}
                                    onChange={(e) => setSendSms(e.target.checked)}
                                    name="sendSms"
                                    color="primary"
                                />
                            }
                            label="Send SMS"
                        />
                    </FormControl>
                    </div>
                </div>

                <div className="form-group row">
                    <input
                        type="submit"
                        value={`Receive ৳${paymentAmount}`}
                        className="btn btn-primary btn-modern w-100"
                        
                    />
                </div>

            </form>

            <ToastContainer />
        </div>

);
};

export default CustomerBillList;